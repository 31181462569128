import styled from '@emotion/styled'
import { Caption } from 'components/Typography'
import { BREAKPOINTS, hiddenScrollbarCss } from 'utils/styled'

export const SActionCardGrid = styled.div<{ scrollable?: boolean }>`
  display: grid;
  height: 100%;
  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  grid-gap: 1rem;

  ${(props) =>
    props.scrollable && {
      gridAutoColumns: '230px',
      gridAutoFlow: 'column',
      padding: '2rem 0.5rem',
      paddingRight: '4rem',
    }}

  @media ${BREAKPOINTS.MD.max} {
    ${(props) =>
      !props.scrollable && {
        gridTemplateColumns: 'repeat(2, minmax(140px, 1fr))',
      }}

    ${hiddenScrollbarCss}
  }
`

export const SActionCards = styled.div``

export const SContainerTitle = styled(Caption)`
  margin-bottom: 2rem;
`

import {
  SActionCardContainer,
  SButtonWrapper,
  SIconRow,
  SText,
  STitle,
  SInfoIcon,
  SIconWrapper,
} from './ActionCard.styled'
import { useLoadingCallback } from 'utils/hooks'
import { Button } from 'components/button/Button'
import { Action } from '../utils'
import { useAuth } from 'utils/auth/hooks/useAuth'
import { InterpolationPrimitive } from '@emotion/serialize'
import { colors } from 'utils/colors'
import { OrangeTag } from 'components/Tag'
import { Text } from 'components/Typography'
import { ButtonProps } from 'components/button/types'
import { Icon } from 'components/icon'
import { useTranslation } from 'next-i18next'

function ResponsiveText(props: { mobile?: string; desktop: string }) {
  const { mobile, desktop } = props

  return (
    <>
      <span className="desktop-only">{desktop}</span>
      <span className="mobile-only">{mobile ?? desktop}</span>
    </>
  )
}

export type ActionCardVariant = 'round-button' | 'text-button'

export function ActionCard({
  className,
  buttonCss,
  variant,
  action: {
    title,
    titleMobile,
    titleAs,
    description,
    descriptionMobile,
    tone,
    icon,
    url,
    urlAs,
    onClick,
    buttonText,
    buttonTextMobile,
    disabled,
    onInfoClick,
    openInNewTab,
    isCompleted,
    duration,
    tag,
  },
  circleAroundIcon,
}: {
  className?: string
  buttonCss?: InterpolationPrimitive
  action: Action
  circleAroundIcon?: boolean
  variant: ActionCardVariant
}) {
  const { t } = useTranslation()
  const [handleClick, isLoading] = useLoadingCallback(() => onClick?.())
  const { isLogged } = useAuth()
  // don't display signup url button if user is logged in
  const isUrlInValid = isLogged && url === '/signup'
  const isUrlValid = !isUrlInValid

  const buttonProps: ButtonProps = {
    type: 'button',
    variant: variant === 'text-button' ? 'text' : 'round',
    tone,
    size: ['small', 'small', 'medium'],
    block: [true, false],
  }

  return (
    <SActionCardContainer className={className}>
      <SIconRow>
        {circleAroundIcon ? <SIconWrapper>{icon}</SIconWrapper> : icon}
        {tag ? (
          <OrangeTag>{tag}</OrangeTag>
        ) : duration ? (
          <Text
            variant="label"
            css={{
              display: 'flex',
              padding: '0.2rem 0.75rem',
              border: '1px solid var(--c-blue100)',
            }}
          >
            {duration}
          </Text>
        ) : null}

        {onInfoClick && (
          <SInfoIcon
            name="QuestionMini"
            color={colors.gray[500]}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              onInfoClick?.()
            }}
          />
        )}
      </SIconRow>
      <STitle
        as={titleAs ?? 'h5'}
        variant={'h7'}
        css={
          isCompleted && {
            color: 'var(--c-gray500)',
            textDecoration: 'line-through',
          }
        }
      >
        <ResponsiveText mobile={titleMobile} desktop={title} />
      </STitle>
      <SText css={isCompleted && { textDecoration: 'line-through' }}>
        <ResponsiveText mobile={descriptionMobile} desktop={description} />
      </SText>
      <SButtonWrapper
        buttonCss={buttonCss}
        css={{
          alignSelf: variant === 'text-button' ? 'end' : 'start',
        }}
      >
        {isCompleted ? (
          <Button {...buttonProps} tone="success" variant="round" leftIcon={<Icon name="Check" />}>
            {t('common.completed')}
          </Button>
        ) : !url || isUrlValid ? (
          <Button
            {...buttonProps}
            loading={isLoading}
            disabled={disabled}
            onClick={handleClick}
            href={url}
            as={urlAs}
            target={openInNewTab ? '_blank' : undefined}
          >
            <ResponsiveText mobile={buttonTextMobile} desktop={buttonText} />
          </Button>
        ) : null}
      </SButtonWrapper>
    </SActionCardContainer>
  )
}

import styled from '@emotion/styled'
import { Text, Title } from 'components/Typography'
import { BREAKPOINTS } from 'utils/styled'
import { InterpolationPrimitive } from '@emotion/serialize'
import { Icon } from 'components/icon'

export const SInfoIcon = styled(Icon)`
  cursor: pointer;

  @media ${BREAKPOINTS.LG.min} {
    display: none;
  }
`

export const SIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.25rem;
  width: 3.25rem;
  border-radius: 50%;
  border: 1px solid var(--c-blue100);
`

export const SActionCardContainer = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: var(--card-shadow);
  border-radius: 2px;
  transition: box-shadow 0.2s;
  padding: 1.5rem;
  overflow: hidden;
  @media ${BREAKPOINTS.MD.max} {
    padding: 1rem;
  }

  justify-content: space-evenly;
  align-items: flex-start;
  &:hover {
    box-shadow: var(--card-shadow-hover);

    ${SInfoIcon} {
      display: flex;
    }
  }

  &:active {
    box-shadow: var(--card-shadow-active);
  }

  @media ${BREAKPOINTS.XL.min} {
    padding: 1.5rem;
  }
`

export const SIconRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 0.75rem;

  @media ${BREAKPOINTS.MD.max} {
    margin-bottom: 0.5rem;
  }
  min-height: 1.75rem;
`

export const STitle = styled(Title)`
  margin-bottom: 0.75rem;
  font-size: 1rem;
  color: black;

  @media ${BREAKPOINTS.MD.max} {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
`

export const SText = styled(Text)`
  flex: 1 1;
  margin-bottom: 0.75rem;
  @media ${BREAKPOINTS.MD.max} {
    margin-bottom: 0.5rem;
  }
`

export const SButtonWrapper = styled.div<{
  buttonCss?: InterpolationPrimitive
}>`
  ${({ buttonCss }) => buttonCss}
`

import { css } from '@emotion/react'
import { InterpolationPrimitive } from '@emotion/serialize'
import { ActionCard, ActionCardVariant } from 'components/actions/actionCard/ActionCard'
import { Action } from 'components/actions/utils'
import { ConditionalWrapper } from 'components/ConditionalWrapper'
import { Controls } from 'components/content/mediaMentions/Controls'
import { HorizontalScroll } from 'components/horizontalScroll/HorizontalScroll'
import React, { useRef } from 'react'
import { matchMap } from 'utils/general'
import { useWindowSize } from 'utils/hooks'
import { SActionCardGrid, SActionCards, SContainerTitle } from './ActionCards.styled'

const actionCardClassName = 'ActionCard'

export type ActionCardsProps = {
  title?: string | null
  gridCss?: InterpolationPrimitive
  scrollerCss?: InterpolationPrimitive
  className?: string
  actions?: Action[]
  scrollability?: 'desktop-only' | 'mobile-only' | 'always'
  hideTitle?: boolean
  buttonCss?: InterpolationPrimitive
  iconsAreChevron?: boolean
  variant: ActionCardVariant
}

export function ActionCards(props: ActionCardsProps) {
  const { actions, title } = props
  const scrollRef = useRef<HTMLDivElement>(null)
  const { isDesktop } = useWindowSize()
  const isScrollable = matchMap(props.scrollability ?? 'undefined', {
    'desktop-only': !!isDesktop,
    'mobile-only': !isDesktop,
    always: true,
    undefined: false,
  })
  const scroll = (direction: 'left' | 'right') => {
    if (!scrollRef.current) return

    const left =
      230 *
      matchMap(direction, {
        left: -1,
        right: 1,
      })

    scrollRef.current.scrollBy({ left, behavior: 'smooth' })
  }

  return (
    <SActionCards className={props.className}>
      {title && !props.hideTitle && <SContainerTitle>{title}</SContainerTitle>}

      <ConditionalWrapper
        condition={isScrollable}
        wrapper={(children) => (
          <>
            <HorizontalScroll
              ref={scrollRef}
              scrollerCss={css`
                padding-top: 0;
                padding-bottom: 0;
                ${props.scrollerCss}
              `}
            >
              {children}
            </HorizontalScroll>
            {isDesktop && (
              <Controls
                slideNext={() => scroll('right')}
                slidePrev={() => scroll('left')}
                iconsAreChevron={props.iconsAreChevron}
              />
            )}
          </>
        )}
      >
        <SActionCardGrid css={[props.gridCss, { width: '100%' }]} scrollable={isScrollable}>
          {actions &&
            actions.map((action, index) => (
              <ActionCard
                variant={props.variant}
                key={index}
                action={action}
                className={actionCardClassName}
                buttonCss={props.buttonCss}
              />
            ))}
        </SActionCardGrid>
      </ConditionalWrapper>
    </SActionCards>
  )
}

import useSWR from 'swr'
import { Await } from 'utils/types'
import { contentful } from './api'

const PREFIX = '@contentful/getHelpCarouselConfig'

async function helpCarouselConfigFetcher() {
  const data = await contentful.getHelpCarousel()

  return data.helpCarouselConfigCollection?.items[0]
}

export function useHelpCarouselConfig() {
  return useSWR<Await<ReturnType<typeof helpCarouselConfigFetcher>>>(
    PREFIX,
    helpCarouselConfigFetcher
  )
}

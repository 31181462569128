import { useHelpCarouselConfig } from 'api/contentful/getHelpCarousel'
import { Block } from 'components/Block'
import {
  SDot,
  SDotsContainer,
  SGallery,
  SHelpCarouselWrapper,
  SItem,
  SLeftButton,
  SListWrapper,
  SRightButton,
  SSubtitle,
} from './HelpCarousel.styled'
import { Icon } from 'components/icon'
import { Text, Title, Title_h2_default } from 'components/Typography'
import { useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import { useInterval } from 'utils/hooks'

function List({ selected, onSelect }: { selected: number; onSelect: (index: number) => void }) {
  const { data } = useHelpCarouselConfig()

  const images = data?.imagesCollection?.items

  return (
    <SListWrapper>
      <SLeftButton className="mobile-only" onClick={() => onSelect(selected - 1)}>
        <Icon name="ChevronRight" />
      </SLeftButton>

      {images?.map((image, index) => (
        <SItem key={image?.sys.id} onClick={() => onSelect(index)} selected={index === selected}>
          <SSubtitle>{image?.title}</SSubtitle>
          <Text>{image?.description}</Text>
        </SItem>
      ))}

      <SRightButton className="mobile-only" onClick={() => onSelect(selected + 1)}>
        <Icon name="ChevronRight" />
      </SRightButton>

      <SDotsContainer className="mobile-only" css={{ marginTop: '1rem' }}>
        {images?.map((_, index) => (
          <SDot key={index} isActive={index === selected} onClick={() => onSelect(index)} />
        ))}
      </SDotsContainer>
    </SListWrapper>
  )
}

export function HelpCarousel({ className }: { className?: string }) {
  const [index, setIndex] = useState(0)
  const [hasUserInteracted, setUserInteracted] = useState(false)

  const { data } = useHelpCarouselConfig()

  const { imagesCollection, title } = data || {}

  const images = imagesCollection?.items

  const setSelected = (selected: number) => {
    if (!images?.length) return

    if (selected >= images?.length) {
      setIndex(0)
    } else if (selected < 0) {
      setIndex(images?.length - 1)
    } else {
      setIndex(selected)
    }
  }

  const onSelect = (selected: number) => {
    setUserInteracted(true)
    setSelected(selected)
  }

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => onSelect(index - 1),
    onSwipedRight: () => onSelect(index + 1),
  })

  useInterval(() => setSelected(index + 1), hasUserInteracted ? null : 3000)

  return (
    <Block {...swipeHandlers} className={className}>
      <SHelpCarouselWrapper>
        <Title variant={Title_h2_default} as="h2" css={{ maxWidth: '32rem' }}>
          {title}
        </Title>

        <SGallery
          images={images?.map((image) => ({ src: image?.url || '', srcSet: '' })) || []}
          selected={index}
        />

        <List selected={index} onSelect={onSelect} />
      </SHelpCarouselWrapper>
    </Block>
  )
}

import styled from '@emotion/styled'
import { ControlledImageGallery } from 'components/ImageGallery'
import { Caption } from 'components/Typography'
import { BREAKPOINTS, pxToRem } from 'utils/styled'

export const SHelpCarouselWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${pxToRem(30)}rem ${pxToRem(40)}rem;

  @media ${BREAKPOINTS.MD.max} {
    grid-template-columns: 1fr;
    grid-gap: ${pxToRem(15)}rem;
  }
`

export const SListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  @media ${BREAKPOINTS.MD.max} {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 1.5rem;
  }
  @media ${BREAKPOINTS.MD.min} {
    & > *:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
`

export const SItem = styled.button<{ selected?: boolean }>`
  border: none;
  outline: none;
  text-align: start;
  background: none;
  cursor: pointer;
  border-left: 2px solid var(--c-blue100);
  padding-left: 20px;
  ${({ selected }) =>
    selected
      ? `
        border-color: var(--c-blue500);
        @media ${BREAKPOINTS.MD.min} {
          & > p {
            color: var(--c-blue500);
          }
        }
    `
      : `
      @media ${BREAKPOINTS.MD.max} {
        display: none;
      }
  `}
  @media ${BREAKPOINTS.MD.max} {
    border: none;
    text-align: center;
    padding: 0;
  }
`

export const SSubtitle = styled(Caption)`
  color: var(--c-gray100);
  margin-bottom: 0.25rem;
`

export const SGallery = styled(ControlledImageGallery)`
  grid-column: 1;
  width: 100%;
  grid-row: 1 / span 2;
  justify-self: center;
  align-self: center;
  @media ${BREAKPOINTS.MD.max} {
    grid-row: 2;
  }
`

const SIconButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  box-shadow: var(--card-shadow);
  width: ${pxToRem(36)}rem;
  height: ${pxToRem(36)}rem;
  color: var(--c-blue500);
  aspect-ratio: 1;
  background-color: white;
`

export const SLeftButton = styled(SIconButton)`
  margin-right: 1rem;
  transform: rotateY(180deg);
`

export const SRightButton = styled(SIconButton)`
  margin-left: 1rem;
`

export const SDotsContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
`

export const SDot = styled.div<{ isActive?: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 6px;
  background-color: ${({ isActive }) => (isActive ? 'var(--c-blue500)' : 'var(--c-blue100)')};
  :hover {
    cursor: pointer;
  }
`

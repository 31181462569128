import { GetStoryPortraitCategoriesQuery } from 'api/contentful/contentful.graphql.types'
import { ContentfulFAQ } from 'api/contentful/getContentfulFAQ'
import { Block } from 'components/Block'
import { BasicTextImageSection } from 'components/content/basicTextImageSection/BasicTextImageSection'
import { HelpCarousel } from 'components/content/helpCarousel/HelpCarousel'
import { ActionCardsSection } from 'components/cornerstone/homepage/ActionCardsSection'
import useMoveFreshDeskButtonResponsively from 'components/directive/utils/useMoveFreshDeskButtonResponsively'
import { FaqTwoColumns } from 'components/faqTwoColumn/FaqTwoColumn'
import { Icon } from 'components/icon'
import { Only } from 'components/only/Only'
import { Signup, ToneContext } from 'components/signup/Signup'
import { Spacer } from 'components/spacer/Spacer'
import { CategorySection } from 'components/storyPortraits/category/CategorySection'
import { useTranslation } from 'next-i18next'
import Router from 'next/router'
import { useContentfulImages } from 'utils/contentful/ContentfulImagesContext'
import { numberRange } from 'utils/general'
import { useWindowSize } from 'utils/hooks'
import { mapKeysToTrans } from 'utils/i18n/mapKeysToTrans'

export const StoryPortraitsPageContent = (props: {
  faq: ContentfulFAQ
  categories: GetStoryPortraitCategoriesQuery
  hideSecuritySection?: boolean
}) => {
  const { t } = useTranslation('memory')
  const images = useContentfulImages()
  const { isDesktop } = useWindowSize()

  useMoveFreshDeskButtonResponsively(-1, -1)

  const signupPerks = mapKeysToTrans(
    t,
    numberRange(4).map((i) => `memory.story.signup_section.perk_${i}` as const)
  )

  return (
    <div>
      <Block>
        <CategorySection categories={props.categories} />
      </Block>

      <Spacer size={[4.25, 4.5]} />

      <Only on="desktop">
        <Block>
          <ActionCardsSection
            iconsAreChevron
            title={t('memory.story.action_cards.title')}
            desc={t('memory.story.action_cards.desc')}
          />
        </Block>

        <Spacer size={4.5} />
      </Only>

      <FaqTwoColumns content={props.faq} />

      <Spacer size={[3, 4.5]} />

      <Only on="mobile">
        <Block>
          <ActionCardsSection
            iconsAreChevron
            textAlignMobile="left"
            title={t('memory.story.action_cards.title').replace('\n', ' ')}
            desc={t('memory.story.action_cards.desc')}
          />
        </Block>
      </Only>

      <Only on="desktop">
        <HelpCarousel />
      </Only>

      {!props.hideSecuritySection && (
        <>
          <Spacer size={[3, 4.5]} />
          <BasicTextImageSection
            orientation="right"
            title={t('memory.story.text_img_section.title')}
            desc={t('memory.story.text_img_section.desc')}
            image={images.story_portraits_security}
            actionTitle={t('memory.story.text_img_section.action_title')}
            actionIcon={<Icon name="Plus" />}
            onAction={() => void Router.push(`/security`)}
          />
        </>
      )}

      <Spacer size={[3, 4.5]} />

      <ToneContext.Provider value="standard">
        <Signup
          caption={isDesktop ? t('memory.story.signup_section.caption') : undefined}
          title={t('memory.story.signup_section.title_desktop')}
          perks={signupPerks}
        />
      </ToneContext.Provider>
    </div>
  )
}

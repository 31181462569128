import { css } from '@emotion/react'
import { spaceLarge } from 'components/Block'
import { ActionCards } from 'components/actions/ActionCards'
import { Icon, IconName } from 'components/icon'
import { IconWrapper } from 'components/iconWrapper/IconWrapper'
import { Stack } from 'components/Stack'
import { Text, Title, Title_h4_default } from 'components/Typography'
import { useTranslation } from 'next-i18next'
import { colors } from 'utils/colors'
import { Namespaces } from 'utils/i18n/types'
import { BREAKPOINTS } from 'utils/styled'

type CardKey = keyof Namespaces['home']['home']['action_cards']['cards']
type CardSpec = {
  iconName: IconName
  bgColor?: string
  iconColor?: string
  url: string
}

const actionCardSpecs: Record<CardKey, CardSpec> = {
  will: {
    url: '/will',
    iconName: 'Will',
    iconColor: colors.orange[200],
    bgColor: colors.orange[100],
  },
  power_of_attorney: {
    url: '/financial-power-of-attorney',
    iconName: 'PowerOfAttorney',
    bgColor: '#E7E8E9',
    iconColor: 'black',
  },
  health_care: {
    url: '/advance-health-care-directive',
    iconName: 'HealthCareDirective',
    iconColor: colors.red[500],
    bgColor: colors.red[100],
  },
  digital_legacy: {
    url: '/digital-executor',
    iconName: 'Family',
    iconColor: colors.green[200],
    bgColor: colors.green[100],
  },
  security: {
    url: '/digital-protection',
    iconName: 'PhoneLock',
    iconColor: colors.blue[500],
    bgColor: colors.blue[100],
  },
  id_theft: {
    url: '/cyberscout-coverage',
    iconName: 'IdentityBadge',
    iconColor: colors.blue[500],
    bgColor: colors.blue[100],
  },
  digital_vault: {
    url: '/digital-vault',
    iconName: 'DigitalVault',
    iconColor: colors.blue[500],
    bgColor: colors.blue[100],
  },
  funeral: {
    url: '/funeral',
    iconName: 'FuneralDirective',
    iconColor: colors.blue[500],
    bgColor: colors.blue[100],
  },
  pet_safety: {
    url: '/pet-directive',
    iconName: 'PetDirective',
    iconColor: colors.blue[500],
    bgColor: colors.blue[100],
  },
}

const ACTION_CARD_KEYS: CardKey[] = [
  'will',
  'power_of_attorney',
  'health_care',
  'digital_legacy',
  'security',
  'id_theft',
  'digital_vault',
  'funeral',
  'pet_safety',
]

export const ActionCardsSection = (props: {
  className?: string
  iconsAreChevron?: boolean
  title?: string
  desc?: string
  textAlignMobile?: 'center' | 'left'
}) => {
  const { t } = useTranslation('home')

  return (
    <section className={props.className}>
      <Stack
        css={[
          { textAlign: 'center', p: { margin: 0 } },
          BREAKPOINTS.MD.css({ textAlign: props.textAlignMobile }),
        ]}
        gap={'1.5rem'}
      >
        <Title variant={Title_h4_default}>{props.title || t('home.action_cards.title')}</Title>
        <Text>{props.desc || t('home.action_cards.description')}</Text>
      </Stack>
      <div
        css={css`
          max-width: 1920px;
          margin: 0 auto;
        `}
      >
        <ActionCards
          iconsAreChevron={props.iconsAreChevron}
          scrollerCss={css`
            padding-left: ${spaceLarge};
          `}
          actions={ACTION_CARD_KEYS.map((key) => {
            const { url, iconName, iconColor, bgColor } = actionCardSpecs[key]
            return {
              title: t(`home.action_cards.cards.${key}.title`),
              description: t(`home.action_cards.cards.${key}.desc`),
              icon: (
                <IconWrapper backgroundColor={bgColor} size={44}>
                  <Icon name={iconName} color={iconColor} />
                </IconWrapper>
              ),
              buttonText: t('home.action_cards.card_action_label'),
              url: url,
            }
          })}
          variant="text-button"
          scrollability="always"
          hideTitle
        />
      </div>
    </section>
  )
}

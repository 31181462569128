import { PageMeta } from 'components/PageMeta'
import { PageTitle } from 'components/PageTitle'
import { ExtendedStoryPortraitMemory } from 'components/storyPortraits/extendedMemory'
import { useTranslation } from 'next-i18next'
import Head from 'next/head'
import { FC } from 'react'
import { formatName } from 'utils/format'

export const StoryPortraitsMeta: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation('memory')
  return (
    <>
      <PageTitle title={t('memory.story.meta_title')} />
      <PageMeta
        description={t('memory.story.meta_desc')}
        image="/images/preview/story-portraits.png"
      />
    </>
  )
}

export const StoryPortraitsSharedMeta: FC<{ memory: ExtendedStoryPortraitMemory | undefined }> = ({
  memory,
}) => {
  const { t } = useTranslation('memory')
  const selected = memory?.selectAnimation()

  return (
    <>
      <PageTitle title={t('memory.story.shared.meta_title')} />
      <PageMeta
        description={t('memory.story.shared.meta_desc', {
          creator: formatName(memory?.response.first_name, memory?.response.last_name, {
            oneLetterLastName: true,
          }),
        })}
        image={memory?.response.original_file?.url}
      />

      {selected ? (
        <Head>
          <meta key="og:type" property="og:type" content="video.other" />
          <meta
            key="og:video"
            property="og:video"
            content={selected?.selectedAnimation?.file?.url}
          />
        </Head>
      ) : undefined}
    </>
  )
}

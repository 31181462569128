import { useEffect } from 'react'
import { useWindowSize } from 'utils/hooks'

const useMoveFreshDeskButtonResponsively = (largeScreenValue = 22, smallScreenValue = 71) => {
  const { isLarge } = useWindowSize()

  useEffect(() => {
    const iFrame = document.getElementById('launcher-frame')
    if (iFrame) {
      if (!isLarge) {
        if (smallScreenValue === -1) {
          iFrame.style.display = 'none'
        } else {
          iFrame.style.bottom = smallScreenValue + 'px'
        }
      } else {
        if (largeScreenValue === -1) {
          iFrame.style.display = 'none'
        } else {
          iFrame.style.bottom = largeScreenValue + 'px'
        }
      }
    }
    return () => {
      if (iFrame) {
        iFrame.style.bottom = '22px'
      }
    }
  }, [largeScreenValue, smallScreenValue, isLarge])
}

export default useMoveFreshDeskButtonResponsively

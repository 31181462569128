import React, { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { wrap } from 'popmotion'
import { useInterval } from 'utils/hooks'

export type ImgSrc = { src: string; srcSet: string }

const swipeVariants = {
  enter: (direction: number) => {
    return { x: direction > 0 ? 1000 : -1000, opacity: 0 }
  },
  center: { zIndex: 1, x: 0, opacity: 1 },
  exit: (direction: number) => {
    return { zIndex: 0, x: direction < 0 ? 1000 : -1000, opacity: 0 }
  },
}

const fadeVariants = {
  enter: { opacity: 0 },
  center: { zIndex: 1, opacity: 1 },
  exit: { zIndex: 0, opacity: 0 },
}

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accommodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity
}

export interface IImageGallery {
  images: ImgSrc[]
  className?: string
  interval?: number | null
  isDraggable?: boolean
}

export const ImageGallery: React.FC<React.PropsWithChildren<IImageGallery>> = ({
  images,
  className,
  interval = 5000,
  isDraggable = false,
}) => {
  const [[page, direction], setPage] = useState([0, 0])

  let variants
  if (isDraggable) {
    variants = swipeVariants
  } else {
    variants = fadeVariants
  }

  useInterval(() => paginate(1), interval)

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection])
  }

  return (
    <AnimatePresence initial={false} custom={direction}>
      <motion.img
        key={page}
        className={className}
        src={images[wrap(0, images.length, page)]?.src}
        srcSet={images[wrap(0, images.length, page)]?.srcSet}
        custom={direction}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          x: { type: 'spring', stiffness: 300, damping: 30 },
          opacity: { duration: 0.5 },
        }}
        drag={isDraggable && 'x'}
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={1}
        onDragEnd={(e, { offset, velocity }) => {
          const swipe = swipePower(offset.x, velocity.x)

          if (swipe < -swipeConfidenceThreshold) {
            paginate(1)
          } else if (swipe > swipeConfidenceThreshold) {
            paginate(-1)
          }
        }}
      />
    </AnimatePresence>
  )
}

export const ControlledImageGallery = ({
  images,
  selected,
  className,
}: {
  images: ImgSrc[]
  selected: number
  className?: string
}) => {
  return (
    <AnimatePresence initial={false}>
      <motion.img
        key={selected}
        className={className}
        src={images[wrap(0, images.length, selected)]?.src}
        srcSet={images[wrap(0, images.length, selected)]?.srcSet}
        initial="enter"
        animate="center"
        variants={fadeVariants}
        exit="exit"
        transition={{
          opacity: { duration: 0.5 },
        }}
      />
    </AnimatePresence>
  )
}

import styled from '@emotion/styled'
import { Button } from 'components/button/Button'
import { Icon } from 'components/icon'
import { PlusLabel } from 'components/modal/storyPortaitsModal/StoryPortraitsModal'
import { HStack } from 'components/Stack'
import { StoryPortraitLogic } from 'components/storyPortraits/logic'
import { Text } from 'components/Typography'
import { useTranslation } from 'next-i18next'
import { useRef } from 'react'
import { colors } from 'utils/colors'
import { useWindowSize } from 'utils/hooks'
import { BREAKPOINTS, pxToRemUnit } from 'utils/styled'

export function StoryPortraitButtons(props: { logic: StoryPortraitLogic; className?: string }) {
  const { t } = useTranslation('memory')
  const { isDesktop } = useWindowSize()
  const ref = useRef<HTMLDivElement>(null)
  const chooseImageButton = props.logic.useChooseImageButton()
  const uploadButton = props.logic.useUploadButton(
    () => ref.current?.querySelector('input[data-dropzone-input]') ?? undefined
  )

  return (
    <SButtonsWrapper className={props?.className} ref={ref}>
      {chooseImageButton.modal}
      <SButton
        type="button"
        size="medium"
        variant="primary"
        customColor={colors.blue[500]}
        onClick={chooseImageButton.onClick}
      >
        <SHStack>
          <HStack>
            <Icon name="ImageFolder" />
            <Text color={colors.white}>{t('memory.story.choose_image_button')}</Text>
          </HStack>
          <Icon name={isDesktop ? 'ChevronDown' : 'ChevronRight'} />
        </SHStack>
      </SButton>

      <input data-dropzone-input {...uploadButton.dropzone.getInputProps()} capture="user" />

      <SButton
        type="button"
        variant="secondary"
        size="medium"
        tone="dark"
        onClick={uploadButton.onClick}
      >
        <SHStack>
          <HStack>
            <Icon name="Cam" />
            <Text className="desktop-only" color={colors.black}>
              {t('memory.story.upload_photo_button_desktop')}
            </Text>
            <Text className="mobile-only" color={colors.black}>
              {t('memory.story.upload_photo_button_mobile')}
            </Text>
          </HStack>
          <div>
            <PlusLabel />
          </div>
        </SHStack>
      </SButton>
    </SButtonsWrapper>
  )
}

const SButtonsWrapper = styled.div`
  display: flex;
  gap: 0.75rem;

  @media ${BREAKPOINTS.MD.max} {
    & > button {
      flex: 1;
    }
  }
  @media ${BREAKPOINTS.LG.max} {
    flex-direction: column;
  }
`

const SButton = styled(Button)`
  border-radius: ${pxToRemUnit(54)};
  min-height: ${pxToRemUnit(54)};
  padding-left: 1.5rem;
  padding-right: 1rem;
  box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.15);
  border: 0;
  ${BREAKPOINTS.MD.css({
    minHeight: '3rem',
  })}
  & > span {
    flex-grow: 1;
  }
`

const SHStack = styled(HStack)`
  justify-content: space-between;
`
